import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Container, Grid } from "@material-ui/core";

class FlexjetRow extends React.Component {
  render() {
    return (
      <div className="Grey md:text-center py-24 multi-column">
        <h3 className="teal">FlexJet offers outstanding performance</h3>
        <Container>
          <Grid
            item
            container
            className="flex flex-row flex-wrap justify-center"
          >
            {this.props.panels && (
              <>
                {this.props.panels.map(panel => {
                  return (
                    <Grid
                      item
                      md={3}
                      sm={6}
                      key={panel.id}
                      className="flex justify-center flex-col items-center"
                    >
                      <img
                        src={panel.pillar_img.publicURL}
                        alt={panel.pillar_title}
                      />
                      <h4 className="blue">{panel.pillar_title}</h4>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </Container>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        strapiJetting {
          flexjet_row {
            id
            pillar_title
            pillar_img {
              publicURL
            }
          }
        }
      }
    `}
    render={data => <FlexjetRow panels={data.strapiJetting.flexjet_row} />}
  />
);
